.contact{
    background-color: black;
    color: white;
    display: flex;
}
.num1{
    margin-left: 40px;
}
.num2{
    margin-left: 60px;
}
.num3{
    margin-left: 60px;
}
.num4{
    margin-left: 300px;
}
.num5{
    margin-left: 50px;
}
.a1{
    background-color: green;
    align-self: flex-start;
}
.num1:hover{
    color: gold;
}
.num2:hover{
    color: gold;
}
.num3:hover{
    color: gold;
}
.num4:hover{
    color: gold;
}
.num5:hover{
    color: gold;
}





